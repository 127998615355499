import Dashboard from "views/panel/dashboard/Dashboard";
// import Timesheet from "views/panel/timesheet/Timesheet";
import Profile from "views/panel/profile/Profile";
import ChangePassword from "views/panel/profile/ChangePassword";
import TrainingStatus from "views/panel/trainingStatus/TrainingStatus";
import ViewLeave from "views/panel/leave/ViewLeave";
import aprovalLeave from "views/panel/leave/aprovalLeave";
import leavetypes from "views/panel/leave/leaveTypes";
import holidaylist from "views/panel/leave/holidaysList";
import employeelist from "views/panel/employee/employee"
import ManageMyTeam from "views/panel/manageMyTeam/ManageMyTeam"
import project from "views/panel/Project/ProjectSetup"
import RolesSetup from "views/panel/projectrole/RolesSetup"
import TaskSetup from "views/panel/projecttask/TaskSetup"

import EmployeeRoster from "views/panel/employeeRoster/EmployeeRoster";
import AddEmployee from "views/panel/addEmployee/AddEmployee";
import contactInfo from "views/panel/addEmployee/ContactInfo";
import educationInfo from "views/panel/addEmployee/EducationInfo";
import ContactInfo from "views/panel/addEmployee/ContactInfo";
import EducationInfo from "views/panel/addEmployee/EducationInfo";
import Timesheetsummary from "views/panel/employeeRoster/timesheetsummary";
import EditEmployee from "views/panel/editEmployee/EditEmployee"
import EditContactInfo from "views/panel/editEmployee/EditContactInfo";
import EditEducationInfo from "views/panel/editEmployee/EditEducationInfo";
import MainInfo from "views/panel/addEmployee/MainInfo";
import ConfigurationPage from "views/panel/ConfigurationPage/configurationPage";
import TitlePage from "views/panel/ConfigurationPage/TitlePage";
import Gender from "views/panel/ConfigurationPage/Gender";
import Holidays from "views/panel/ConfigurationPage/Holidays"
import Country from "views/panel/ConfigurationPage/Country";
import Certificate from "views/panel/ConfigurationPage/Certificate";
import Document from "views/panel/ConfigurationPage/Document";
import Roles from "views/panel/ConfigurationPage/Roles";
import EmploymentType from "views/panel/ConfigurationPage/EmploymentType";
import WorkLocation from "views/panel/ConfigurationPage/WorkLocation";
import YearsOFExperience from "views/panel/ConfigurationPage/YearsOfExperience";
import States from "views/panel/ConfigurationPage/States";
import Cities from "views/panel/ConfigurationPage/Cities";
import Degree from "views/panel/ConfigurationPage/Degree";
import BloodGroup from "views/panel/ConfigurationPage/BloodGroup";
import MaritalStatus from "views/panel/ConfigurationPage/MaritalStatus";
import Designations from "views/panel/ConfigurationPage/Designations";
import Relationship from "views/panel/ConfigurationPage/Relationship";
import Departments from "views/panel/ConfigurationPage/Departments";
import Banks from "views/panel/ConfigurationPage/Bank";
import BankBranches from "views/panel/ConfigurationPage/BankBranches"
import AddNewProject from "views/panel/Project/AddNewProject";
import AddNewRole from "views/panel/projectrole/AddNewRole";
import AddNewTask from "views/panel/projecttask/AddNewTask";
import EditNewProject from "views/panel/Project/EditNewProject";
import EditNewRole from "views/panel/projectrole/EditNewRole";
import EditNewTask from "views/panel/projecttask/EditNewTask";
import Timesheet from "views/panel/timesheetnew/Timesheet";
import projectType from "views/panel/ConfigurationPage/Project";
import Reports from "views/panel/reports/Reports";
import Settings from "views/panel/ConfigurationPage/Settings";
import Permissions from "views/panel/ConfigurationPage/Permissions";
import WeeklyReport from "views/panel/reports/Weeklreport/WeeklyReport";
import LeaveDashboard from "views/panel/LeaveManagement/LeaveDashboard";
import LeaveApply from "views/panel/LeaveManagement/LeaveApply/LeaveApply";
import TimesheetTaskTable from "views/panel/timesheetnew/TimesheetTaskTable";
import LeaveMain from "views/panel/LeaveManagement/LeaveMain";
import LeaveHistory from "views/panel/LeaveManagement/LeaveHistory/LeaveHistory";
import TimesheetSummaryView from "views/panel/reports/TimesheetSummaryReports/TImesheetSummaryView";
import ManagerLeaveHistory from "views/panel/LeaveManagement/managerView/ManagerLeaveHistory";
import Trainings from "views/panel/trainings/Trainings";
import EditTrainings from "views/panel/trainings/EditTrainings";

var childRoutes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   component: Dashboard,
  //   permissionName: "VIEW_DASHBOARD",
  // },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    permissionName: "VIEW_PROFILE",
  },
  {
    path: "/change_password",
    name: "Change Password",
    component: ChangePassword,
    permissionName: "VIEW_PROFILE",
  },
  {
    path: "/employeeList",
    name: "Employee List",
    component: employeelist,
    permissionName: "VIEW_EMPLOYEE",
  },
  {
    path: "/Employeeinformation",
    name: "Employee Information",
    component: EmployeeRoster,
    permissionName: "VIEW_EMPLOYEE"  
  },
  {
    path: "/timesheetsummary",
    name: "timesheetsummary",
    component: Timesheet,
    permissionName: "VIEW_TIMESHEET"  
  },
  {
    path: "/addEmployee",
    name: "addEmployee",
    component: EmployeeRoster,
    permissionName: "VIEW_EMPLOYEE"  
  },
  {
    path: "/contactInfo",
    name: "contactInfo",
    component: ContactInfo,
    permissionName: "VIEW_ADMIN"  
  },
  {
    path: "/educationInfo",
    name: "educationInfo",
    component: EducationInfo,
    permissionName: "VIEW_ADMIN"  
  },
  {
    path: "/editEmployee/:id",
    name: "editEmployee",
    component: EditEmployee,
    permissionName: "VIEW_EMPLOYEE"
  },
  {
    path: "/editContactInfo",
    name: "editContactInfo",
    component: EditContactInfo,
    permissionName: "VIEW_EMPLOYEE"
  },
  {
    path: "/EditEducationInfo",
    name: "EditEducationInfo",
    component: EditEducationInfo,
    permissionName: "VIEW_EMPLOYEE"
  },
  {
    path: "/mainInfo",
    name: "mainInfo",
    component: MainInfo,
    permissionName: "VIEW_EMPLOYEE"
  },
  {
    path: "/configurationPage",
    name: "configurationPage",
    component: ConfigurationPage,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/TitlePage",
    name: "TitlePage",
    component: TitlePage,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/Gender",
    name: "Gender",
    component: Gender,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/Holidays",
    name: "Holidays",
    component: Holidays,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/Country",
    name: "Country",
    component: Country,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/Certificate",
    name: "Certificate",
    component: Certificate,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/Document",
    name: "Document",
    component: Document,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/Roles",
    name: "Roles",
    component: Roles,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/EmploymentType",
    name: "EmploymentType",
    component: EmploymentType,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/WorkLocation",
    name: "WorkLocation",
    component: WorkLocation,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/YearsOFExperience",
    name: "YearsOFExperience",
    component: YearsOFExperience,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/States",
    name: "States",
    component: States,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/Cities",
    name: "Cities",
    component: Cities,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/Degree",
    name: "Degree",
    component: Degree,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/BloodGroup",
    name: "BloodGroup",
    component: BloodGroup,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/MaritalStatus",
    name: "MaritalStatus",
    component: MaritalStatus,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/Designations",
    name: "Designations",
    component: Designations,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/Relationship",
    name: "Relationship",
    component: Relationship,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/Departments",
    name: "Departments",
    component: Departments,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/Banks",
    name: "Banks",
    component: Banks,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/BankBranches",
    name: "BankBranches",
    component: BankBranches,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/projectType",
    name: "projectType",
    component: projectType,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/project",
    name: "project",
    component: project,
    permissionName: "VIEW_PROJECT"  
  },
  {
    path: "/AddProject",
    name: "AddProject",
    component: AddNewProject,
    permissionName: "VIEW_PROJECT"
  },
  {
    path: "/EditProjectTask/:id",
    name: "EditProjectTask",
    component: EditNewProject,
    permissionName: "VIEW_PROJECT"
  },
  {
    path: "/projectrole",
    name: "Project Role",
    component: RolesSetup,
    permissionName: "VIEW_ROLE"  
  },
  {
    path: "/AddRole",
    name: "AddRole",
    component: AddNewRole,
    permissionName: "VIEW_ROLE"
  },
  {
    path: "/EditProjectRole/:id",
    name: "EditProjectRole",
    component: EditNewRole,
    permissionName: "VIEW_ROLE"
  },
  {
    path: "/projecttask",
    name: "Project Task",
    component: TaskSetup,
    permissionName: "VIEW_TASK"  
  },
  {
    path: "/AddTask",
    name: "AddTask",
    component:AddNewTask,
    permissionName: "VIEW_TASK"
  },
  {
    path: "/EditTask/:id",
    name: "EditTask",
    component:EditNewTask,
    permissionName: "VIEW_TASK"
  },
  {
    path: "/Settings",
    name: "Settings",
    component:Settings,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/Permissions",
    name: "Permissions",
    component:Permissions,
    permissionName: "VIEW_CONFIG"
  },
  {
    path: "/leaveManagement",
    name: "leaveManagement",
    component:LeaveDashboard,
    permissionName: "VIEW_LEAVE_MANAGEMENT"
  },
  {
    path: "/leaveApply",
    name: "leaveApply",
    component:LeaveApply,
    permissionName: "VIEW_LEAVE_MANAGEMENT"
  },
  {
    path: "/leaveHistory",
    name: "leaveHistory",
    component:LeaveHistory,
    permissionName: "VIEW_LEAVE_MANAGEMENT"
  },
  {
    path: "/leaveMain",
    name: "leaveMain",
    component:LeaveMain,
    permissionName: "VIEW_LEAVE_MANAGEMENT"
  },

  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    permissionName: "VIEW_REPORT"  
  },
  {
    path: "/weeklyreports",
    name: "weeeklyreports",
    component: WeeklyReport,
    permissionName: "VIEW_REPORT"  
  },
  {
    path: "/timesheetTaskTable",
    name: "timesheetTaskTable",
    component: TimesheetTaskTable,
    permissionName: "VIEW_REPORT"  
  },
  {
    path: "/timesheetSummaryView",
    name: "timesheetSummaryView",
    component: TimesheetSummaryView,
    permissionName: "VIEW_REPORT"  
  },
  {
    path: "leaveManagement/managerView",
    name: "leaveManagement",
    component: ManagerLeaveHistory,
    permissionName: "VIEW_LEAVE_MANAGEMENT"
  },
  {
    path: "/trainings",
    name: "Trainings",
    component: Trainings,
    permissionName: "VIEW_LEAVE_MANAGEMENT"
  },
  {
    path: "/edittrainings",
    name: "EditTrainings",
    component:EditTrainings,
    permissionName: "VIEW_LEAVE_MANAGEMENT"
  },

  
];

export default childRoutes;
