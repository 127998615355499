// npm packages
import * as React from "react";
import { useState, useEffect, useContext } from "react";
import Context from "context/context";
import * as moment from "moment";
import { toast } from "react-toastify";

// custom pages
import tick from "assets/images/Frame 41.png";
import cross from "assets/images/Frame 80.png";
import leftarrow from "assets/images/arrow-right.png";
// import Timesheet from "./Timesheet";
import getAllProject from "apis/panel/timesheet/TimeSheet";
import ApprovalApi from "apis/panel/timesheet/TimeSheet";
import Tables from "components/table/table";
import leaveManagementAPI from "apis/panel/leaveManagemnet/leaveManagement";
import fileHandler from "apis/panel/fileHandler/fileHandler";
import pdflogo from "assets/images/pdfimage.png"
import "./LeaveHistory.css"

// material-ui
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Switch from "@mui/material/Switch";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Pagination from '@mui/material/Pagination';
import LeaveApply from "../LeaveApply/LeaveApply";
import LeaveDashboard from "../LeaveDashboard";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from "../../../../../node_modules/react-router-dom/dist/index";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

// import { Padding } from "../../../../node_modules/@mui/icons-material/index";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    cursor: "pointer",
    backgroundColor: "#002c5d",
    color: "#ffffff",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: "40%"
};

const LeaveHistory = () => {
  const { state, dispatch } = useContext(Context);
  // const Navigate = useNavigate()

  const [showTimeSheetTable, setShowTimeSheetTable] = useState(true);
  const [roleCode] = useState(state.role.roleCode);
  const [leaveDetails, setleaveDetails] = useState([]);
  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [reject, setReject] = useState(false);
  const [view, setView] = useState(true);
  const [SwitchVal, setSwitchVal] = useState("");
  const [employeeLeaveRequestId, setEmployeeLeaveRequestId]= useState('');
  const Navigate= useNavigate()

  const getStatusColor = (approvalStatus) => {
    switch (approvalStatus) {
      case "Pending":
        return "#E97000";
      case "Approved":
        return "#008A27";
      case "Rejected":
        return "#E90000";
      case "Contract":
        return "#0079E9";
      default:
        return "inherit";
    }
  };

  const getAStatusColor = (approvalStatus) => {
    switch (approvalStatus) {
      case "Pending":
        return "#E97000";
      case "Applied":
        return "#008A27";
      case "Withdrawn":
        return "#E90000";
      case "Contract":
        return "#0079E9";
      default:
        return "inherit";
    }
  };

  const [selectedLeaveDetails, setSelectedLeaveDetails] = useState(null);

  const [disabled, setDisabled] = useState(false);
  const { getAllProjectAPI } = getAllProject();
  const { putApprovalAPI, updateApprovalAPI } = ApprovalApi();

  const { GetAllLeavesStatusAPI, ManagerUpdateLeaveRequestAPI, withdrawLeaveRequestAPI } = leaveManagementAPI()
  const { fileDownloadAPI } = fileHandler()

  const toggleComponent = () => {
    setShowTimeSheetTable((prevState) => !prevState);
  };

  const handleView = (isChecked) => {
    setView(isChecked);
    setSwitchVal(isChecked ? "My View" : "Manager View");
    toggleComponent();
  };

  const [open, setOpen] = useState(false);
  const handleOpen = (leaveDetails) => {
    setSelectedLeaveDetails(leaveDetails);
    setOpen(true);
  };

  useEffect(() => {
    setDisabled(reject);
  }, [reject]);

  const handleReject = () => {
    setReject(true);
  };

  const handleGetAllLeavesApi = async () => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let values;
      values = {
        offset: (offset - 1) * 10,
        limit: 10,
      };
      if (searchValue) {
        values = {
          ...values,
          searchValue,
        };
      }
      if (filterValue) {
        values = {
          ...values,
          filterValue,
        };
      }
      let res = await GetAllLeavesStatusAPI(values);
      setleaveDetails(res?.data?.employees || []);
      setCount(res?.data?.count);
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };


  const handleUpdateAllLeavesApi = async (status) => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let value;

      value = {
        employeeLeaveRequestId: selectedLeaveDetails.employee_leave_request_id,
        reason: "testing"
      };
      let res = await withdrawLeaveRequestAPI(value);
      handleGetAllLeavesApi()
      setOpen(false)

      dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };


  const handleApprove = () => {
    handleUpdateAllLeavesApi("Approved");
    setOpen(false); // Close the modal
  };

  // Decline button handler
  const handleDecline = () => {
    handleUpdateAllLeavesApi("Rejected");
    setOpen(false); // Close the modal
  };

  const handleLeavereqManageent = () => {
    Navigate("/panel/leaveApply")
  }

  useEffect(() => {
    handleGetAllLeavesApi();
  }, [offset, searchValue, filterValue]);

  const handlePagination = (value) => {
    setOffset(value);
  };


  const handleNavigation=()=>{
    Navigate("/panel/leaveManagement")
  }
  const handleDownloadLeaveDetails= async(id)=>{
    dispatch({ type: "SET_LOADING", payload: true });
    const res = await fileDownloadAPI(id)
    dispatch({ type: "SET_LOADING", payload: false });


  }
  return (
    <div>
 
        <>
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
            }}
          >
            <Tables
              title={
                <div style={{display:"flex", gap: "3%",alignItems:"center" ,paddingTop:"2%"}}>
      <div style={{alignItems:"center", paddingTop:2}}  onClick={handleNavigation} ><img  src={leftarrow}/></div>
      <div className='leaveapplyfirstdivleave'> Leave History</div>
      </div>
              }
              searchBar={true}
              filter={true}
              role={roleCode}
              switchView={
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Button className="rlreq" onClick={handleLeavereqManageent}>
                <div className="rlreqlevreqButton1">Raise Leave Request</div>
              </Button>
                 
                </Grid>
              }
              setSearchValue={setSearchValue}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              filterValues={[
                { label: "Latest", value: "" },
                { label: "Oldest", value: "oldest" },
                // { label: "Employee Id", value: "employeeCode" },
                // { label: "Employee Name", value: "employeeName" },
              ]}
              heading={[
                "S.No",
                "Leave Type",
                "Start Date",
                "End Date",
                "Total Days",
                "Request Status",
                "Review Status",
                "Action",
              ]}
              data={leaveDetails?.map((e, index) => [
                index + 1 + (offset - 1) * 10,
             
                e?.leaveTypeName,
                <span
                  style={{
                    fontWeight: "700",
                    color: "#008A27",
                  }}
                >
                  {e.startDate
                    ? moment(e.startDate).format("DD-MMM-YYYY")
                    : ""}
                </span>,
                <span
                  style={{
                    fontWeight: "700",
                    color: "#E90000",
                  }}
                >
                  {e.endDate
                    ? moment(e.endDate).format("DD-MMM-YYYY")
                    : ""}
                </span>,
                e?.numberOfDays,
          
              <span style={{
                color: getAStatusColor(e?.requestStatus),
                fontWeight: "700",
              }}>{e?.requestStatus}</span>,
              <span style={{
                color: getStatusColor(e?.approvalStatus),
                fontWeight: "700",
              }}>{e?.approvalStatus}</span>,

              <CustomWidthTooltip title="Click to view details">
                <span className="jobType">
                  <RemoveRedEyeIcon
                    style={{
                      color: "#FE602F",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                    onClick={() => handleOpen(e)}
                  />
                </span>
              </CustomWidthTooltip>
            ])}
            offset={offset}
            setOffset={setOffset}
            count={count}
            placeholder="Search By Leave Type"
          />
        </div>

        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}
            style={{
              // minHeight: "300px",
              maxHeight: "450px",
              overflowY: "auto",
              borderRadius: "10px",
              border: 'none'
            }}
          >
            {/* Close Button */}
            <IconButton
              aria-label="close"
              onClick={() => setOpen(false)}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                color: "inherit",
                zIndex: 1,
              }}
            >
              <CloseIcon />
            </IconButton>

            {/* Project Information */}
            <Grid container style={{ alignItems: "center" }}>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                style={{ display: "flex", gap: "1%" }}
              >
                <div className="tsheads">Leave Type :</div>
                <div className="tsbods">{selectedLeaveDetails?.leaveTypeName}</div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                style={{ display: "flex", gap: "1%", pl: 2 }}
              >
                <div className="tsheads">Total Days :</div>
                <div className="tsbods">{selectedLeaveDetails?.numberOfDays}</div>
              </Grid>
             
            </Grid>

            <Grid container sx={{mt:2}}>
              <Grid item xs={12} sm={4} lg={4} md={4}>
                <Box className="managerLeaveModalhead">From Date</Box>
                <Box className="tsbods">{selectedLeaveDetails?.startDate ? moment(selectedLeaveDetails.startDate).format("DD-MMM-YYYY") : ""}</Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={6} md={6}>
                <Box className="managerLeaveModalhead">To Date</Box>
                <Box className="tsbods">{selectedLeaveDetails?.endDate ? moment(selectedLeaveDetails.endDate).format("DD-MMM-YYYY") : ""}</Box>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={12} lg={12} md={12} sx={{ mt: 4 }} className="managerLeaveModalhead">Attachments</Grid>
             
              {selectedLeaveDetails?.filePath ? (
                <Grid item xs={12} sm={12} lg={12} md={12}>
                <Box style={{ display: "flex", gap: "1%", cursor: "pointer" }}
                  onClick={() => handleDownloadLeaveDetails(selectedLeaveDetails?.filePath)}>
                  <div><DownloadForOfflineIcon
                    style={{ fontSize: 25, verticalAlign: "middle", color: '#0070E9' }}
                  /></div>
                  <div className="tsbods" style={{ marginTop: '4px' }} >Click to Download</div>

                </Box>
              </Grid> 
              ):(
                <Box className="editvalue"> -</Box>
              )}
             
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={12} lg={12} md={12} sx={{ mt: 4 }} className="managerLeaveModalhead">Reason</Grid>
              <Grid item xs={12} sm={12} lg={12} md={12} className="tsbods">{selectedLeaveDetails?.reason}</Grid>
            </Grid>

            <Grid container style={{justifyContent:"center", marginTop:"2%"}}>
            {selectedLeaveDetails?.requestStatus != 'Withdrawn' &&(
                  <Grid
                   // Ensure you have a unique key for each Grid item
                  item
                  xs={12}
                  sm={4}  
                  md={4}
                  lg={4}
                  style={{ display: "flex", justifyContent: "end", textAlign: "right" }}
                >
                  <Button className="reqwithdrawbuttoncont" onClick={handleUpdateAllLeavesApi}>
                  Withdraw 
                  </Button>
                </Grid>
              )
            }
               
            </Grid>
          </Box>
        </Modal>
      </>

      {/* {showTimeSheetTable ? leaveDetails.length == 0 && (
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
          }}
        >
          <Tables
            title={
              <Box className="EmployeeRoster" sx={{ pt: 2, pb: 2, pl: 2 }}>
                Manager View
              </Box>
            }
            searchBar={true}
            filter={true}
            role={roleCode}
            switchView={
              <Grid
                item
                xs={12}
                style={{ display: "flex", alignItems: "center" }}
              >
                <CustomWidthTooltip
                  title={
                    <span>
                      Toggle to switch back to Timesheet View <br />
                      to fill and submit your timesheet.
                    </span>
                  }
                >
                  <div className="Switch-div">
                    <Switch
                      style={{ color: "#0070E9" }}
                      checked={view}
                      onChange={(e) => handleView(e.target.checked)}
                    />
                    <h3 style={{ margin: "7px 0px 0px 0px", color: "#0070E9" }}>
                      {SwitchVal}
                    </h3>
                  </div>
                </CustomWidthTooltip>
                <div className="tsbods" style={{ color: "#0070E9" }}>
                  Back to My Timesheet
                </div>
              </Grid>
            }
            setSearchValue={setSearchValue}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            filterValues={[
              { label: "Start Date", value: "" },
              { label: "Employee Id", value: "employeeCode" },
              { label: "Employee Name", value: "employeeName" },
            ]}
            offset={offset}
            setOffset={setOffset}
            count={0}
            placeholder="Search by Employee ID & Employee Name"
          />
        </div>
      ) : (
        <LeaveDashboard />
      )} */}
    </div>
  );
};

export default LeaveHistory;
