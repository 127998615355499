import GroupsIcon from "@mui/icons-material/Groups";

import EmployeeInformationLight from "assets/images/sidebar/employee-roster-light.png";
import TimesheetLight from "assets/images/sidebar/timesheet-light.png";
import ConfigOptionLight from "assets/images/sidebar/config-option-light.png";

import EmployeeInformationDark from "assets/images/sidebar/employee-roster-dark.png";
import TimesheetDark from "assets/images/sidebar/timesheet-dark.png";

import rolelight from "assets/images/sidebar/role-light.png";
import roledark from "assets/images/sidebar/role-dark.png";
import taskdark from "assets/images/sidebar/task-dark.png";
import tasklight from "assets/images/sidebar/task-light.png";
import projectlight from "assets/images/sidebar/project-light.png";
import projectdark from "assets/images/sidebar/project-dark.png"
import learndark from 'assets/images/sidebar/local_library_FILL0_wght400_GRAD0_opsz24.svg'
import learnlight from 'assets/images/sidebar/local_library_FILL0_wght400_GRAD0_opsz24 2.svg'
import LeaveLight from "assets/images/sidebar/Leavelight.png"
import LeaveDark from "assets/images/sidebar/badge_FILL0_wght400_GRAD0_opsz24 1.png"
import EmployeeHandbookLight from "assets/images/sidebar/employee-handbook-light.svg"
import ReportLight from "assets/images/sidebar/report-light.svg"
import ReportDark from "assets/images/sidebar/report-dark.svg"
import trainings from "assets/images/sidebar/training.png"

var sideBarRoutes = [
  {
    name: "Employee Information",
    to: "/panel/EmployeeInformation",
    permissionName: "VIEW_EMPLOYEE",
    lightIcon: EmployeeInformationLight,
    darkIcon: EmployeeInformationDark,
  },

  {
    name: "Timesheet",
    to: "/panel/timesheetsummary",
    permissionName: "VIEW_TIMESHEET",
    icon: <GroupsIcon />,
    lightIcon: TimesheetLight,
    darkIcon: TimesheetDark,
  },

  {
    name: "Roles",
    to: "/panel/projectrole",
    permissionName: "VIEW_ROLE",
    lightIcon:rolelight,
    darkIcon:roledark,
  },

  {
    name: "Project Setup",
    to: "/panel/project",
    permissionName: "VIEW_PROJECT",
    lightIcon: projectlight,
    darkIcon: projectdark,
  },

  {
    name: "Task Setup",
    to: "/panel/projecttask",
    permissionName: "VIEW_TASK",
    lightIcon: taskdark,
    darkIcon: tasklight,
  },

  {
    name: "Config Option",
    to: "/panel/configurationPage",
    permissionName: "VIEW_CONFIG",
    lightIcon: ConfigOptionLight, 
    darkIcon: ConfigOptionLight,
  },

  {
    name: "Reports",
    to: "/panel/reports",
    permissionName: "VIEW_REPORT",
    lightIcon: ReportLight, 
    darkIcon: ReportDark,
  },

  {
    type: "TP",
    name: "Training Nexus",
    // to: "http://localhost:4000/hrms",
    to: "https://training-nexus.valuehealthsolutions.com/hrms",
    permissionName: "VIEW_TRAINING_NEXUS",
    lightIcon: learnlight, 
    darkIcon: learndark,
  },

  {
    name: "Leave Management",
    to: "/panel/leaveManagement",
    permissionName: "VIEW_LEAVE_MANAGEMENT",
    lightIcon: LeaveLight, 
    darkIcon: LeaveDark,
    subRoutes: [
      {
        name: "Manager View",
        to: "/panel/leaveManagement/managerView",
        permissionName: "VIEW_LEAVE_MANAGEMENT_MANAGER_VIEW",
      },
      {
        name: "My View",
        to: "/panel/leaveManagement",
        permissionName: "VIEW_LEAVE_MANAGEMENT_MANAGER_VIEW",
      },
    ],
  },

// {
//   name: "Trainings",
//   to: "/panel/trainings",
//   permissionName: "VIEW_LEAVE_MANAGEMENT",
//   lightIcon: LeaveLight, 
//   darkIcon: trainings,
// },

  {
    type:"employee-handbook-document",
    name: "Employee Handbook",
    to: "static/EmployeeHandbook.pdf",
    permissionName: "VIEW_EMPLOYEE_HANDBOOK",
    lightIcon: EmployeeHandbookLight, 
    darkIcon: EmployeeHandbookLight,
  },

];

export default sideBarRoutes;
