import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import "./EmployeeRoster.css";
import Tables from "components/table/table";
import getallList from "apis/panel/EmployeeRoster/employeeRoster";
import Context from "context/context";
import getcreatefindone from "apis/panel/AddEmployee/addEmployee"
import Button from "@mui/material/Button";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import check from "../../../assets/images/check.png";
import cross from "../../../assets/images/remove.png"

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    cursor: "pointer",
    backgroundColor: "#002c5d",
    color: "#ffffff",
  },
});

const EmployeeRoster = () => {
  const { state, dispatch } = useContext(Context);
  const [roleCode] = useState(state.role.roleCode);
  const navigate = useNavigate();
  const { getAllEmployeeAPI } = getallList();
  const [empDetails, setEmpDetails] = useState([]);
  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [serialNumber, setSerialNumber] = useState("");

  const handleNavigate = () => {
    navigate("/panel/mainInfo");
  };

  const getJobTypeColor = (employmentTypeName) => {
    switch (employmentTypeName) {
      case "Internship":
        return "#E97000";
      case "Full-Time":
        return "#008A27"; 
      case "Part Time":
        return "#E90000";
      case "Contract":
        return "#0079E9";
      default:
        return "inherit";
    }
  };




  useEffect(() => {
    handleGetAllemployeesApi();    
  }, [offset, searchValue, filterValue]);

  const handleGetAllemployeesApi = async () => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let values;
      values = {
        offset: (offset - 1) * 10,
        limit: 10,
      };
      if (searchValue) {
        values = {
          ...values,
          searchValue,
        };
      }
      if (filterValue) {
        values = {
          ...values,
          filterValue,
        };
      }
      let res = await getAllEmployeeAPI(values);
      setEmpDetails(res?.data?.employees || []);
      setCount(res?.data?.count);
      setSerialNumber(offset)
      dispatch({ type: "SET_LOADING", payload: false });
    } 
    catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };

  const handleEmployeeView = async (eid) => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      navigate("../editemployee/"+eid);
      dispatch({ type: "SET_LOADING", payload: false });
    } 
    catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
    }    
  }


  return (
    <div
     className="employeeRosterMediaQuery"
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
      }}
    >
      
      <Tables className="employeeRosterMediaQuery"
        title={
          <Box className="EmployeeRoster" sx={{ pt:2, pb:2, pl:1}}>Employee Information</Box>
        }
        searchBar={roleCode === "R001"}
        filter={roleCode === "R001"}
        role={roleCode}
        create={
          (roleCode === "R001") &&
          <Button className="AddEmployerButton" onClick={handleNavigate}>
            Add Employee
          </Button>
        }
        setSearchValue={setSearchValue}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        filterValues={[
          { label: "Employee Id", value: "" },
          { label: "Employee Name", value: "employeeName" },
          { label: "Employee Email", value: "employeeEmail" },
        ]}
        heading={[
          // "S.No",
          "Employee Id",
          "Employee Name",
          "Reporting Manager",
          "Department",
          "Business Email",
          "Employee Type",
          "Status",
          "Action",
        ]}
       
        data={empDetails?.map((employee, index) => [
          // index + 1 + (serialNumber - 1) * 10,
          employee.employeeCode,
          employee.firstName,
          employee?.reportingManager,
          employee.departmentName,
          <span className="businessmail">
            {employee.businessEmail.split("@")[0]}@<br />
            {employee.businessEmail.split("@")[1]}
          </span>,

          <span
            style={{
              color: getJobTypeColor(employee?.employmentTypeName),
              fontWeight: "700",
            }}
          >
            {employee.employmentTypeName}
          </span>,

employee.status === "Active" ? (
  <img style={{height: "20px"}} src={check} alt=""/>
) : (
  <img  style={{height: "20px"}} src={cross} alt=""/>
),
              <CustomWidthTooltip
              title='Click to view details'
              >  
          <span className="jobType">

            <RemoveRedEyeIcon
              style={{ color: '#E97000', cursor:'pointer' }}
              onClick={(e) => 
                handleEmployeeView(employee.employeeId)
                // navigate("../editemployee/"+employee.employeeId)
              }
            />
          </span>
          </CustomWidthTooltip>
        ])}
        offset={offset}
        setOffset={setOffset}
        count={count}
        placeholder="Search by ID, Name, Email"
      />
    </div>
  );
};

export default EmployeeRoster;
