import React, { useState, useEffect, useContext } from 'react';
import Context from 'context/context';
import './TimesheetSummaryReportView.css';
import Grid from '@mui/material/Grid';

import Box from '@mui/material/Box';

import Pagination from '@mui/material/Pagination';
import reportsAPI from 'apis/panel/reports/reports';
import { useLocation } from 'react-router-dom';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from '@mui/material/Paper';
import Loader from 'components/Loader';


const decodeQueryStringToState = (queryString) => {
  const params = new URLSearchParams(queryString);
  const body = {};

  for (const [key, value] of params.entries()) {
    if (value.startsWith('array:')) {
      body[key] = JSON.parse(value.substring(6)); // Remove the prefix and parse
    } else {
      body[key] = value;
    }
  }

  return body;
};



const TimesheetSummaryView = () => {
  
  const { dispatch } = useContext(Context); 
  const location = useLocation();

  const body = decodeQueryStringToState(location.search.substring(1));
  
 
  const [projectSummaryReportList, setProjectSummaryReportList] = useState([]);
  const [offset, setOffset] = useState(1);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  const [expandedRows, setExpandedRows] = useState({});

    const handleToggleReadMore = (index) => {
        setExpandedRows((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };
    const MAX_DESCRIPTION_LENGTH = 30;
    const renderDescription = (description, index) => {
        if (!description) return null;

        const isExpanded = expandedRows[index];
        const shortDescription = description.slice(0, MAX_DESCRIPTION_LENGTH);

        return (
            <span>
                {isExpanded ? description : shortDescription}
                {description.length > MAX_DESCRIPTION_LENGTH && (
                    <span onClick={() => handleToggleReadMore(index)} style={{ color: 'blue', cursor: 'pointer' }}>
                        {isExpanded ? ' Show less' : '... '}
                    </span>
                )}
            </span>
        );
    };
 
  const { getTimesheetSummaryReportViewAPI } = reportsAPI();
  
 
  const itemsPerPage = 10;

  const handlePagination = (value) => {
    setOffset(value);
  };

  const handleGetTimesheetSummaryView = async () => {
   
    if (body) {
      let values = {
        offset: (offset - 1) * 10,
        limit: 10,
        ...body,
        
      };
     
      try {
        dispatch({ type: 'SET_LOADING', payload: true });

        let res = await getTimesheetSummaryReportViewAPI(values);
        
        if(res?.type=='SUCCESS'){
          
          setCount(res?.count);
          setProjectSummaryReportList(res?.data || []);
        }
       
        dispatch({ type: 'SET_LOADING', payload: false });
      } catch (err) {
        setLoading(false);
      }
    }
  };

 
  useEffect(() => {
    handleGetTimesheetSummaryView()
  }, [offset]);



  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // This will format the date as "Mon Jul 05 2021"
};

 

  return (
    <div style={{ backgroundColor: 'white', borderRadius: '10px' }}>
      <Grid container sx={{ alignItems: 'center', p: 2 }}>
        <Grid item xs={12} md={4.5} sm={4.5} lg={4.5} className="TimesheetReportView1stheading">
          Project Level Summary Report - View 
        </Grid>

      </Grid>


<Grid container className="timesheetSummarymediaquery">
      <Grid item md={12}  lg={12} xl={12} sm={12} style={{ width:"100%"}}>
      <TableContainer component={Paper} style={{ width:"100%", overflowX: 'auto' }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className= "theadtablecellTimesummary"><b> S.No</b></TableCell>
                                   <TableCell className= "theadtablecellTimesummary"><b>Project Name</b></TableCell>
                                  <TableCell className= "theadtablecellTimesummary"><b>Account Name</b></TableCell>
                                    <TableCell className= "theadtablecellTimesummary"><b>Project Type</b></TableCell>
                                    <TableCell className= "theadtablecellTimesummary"><b >Employee Full Name</b></TableCell>
                                    <TableCell className= "theadtablecellTimesummary"><b >Employee Business Email</b></TableCell>
                                    <TableCell className= "theadtablecellTimesummary"><b >Employee ID</b></TableCell>       
                                   <TableCell className= "theadtablecellTimesummary"><b >Date of Joining</b></TableCell>
                                   <TableCell className= "theadtablecellTimesummary"><b >Work Location</b></TableCell>
                                   <TableCell className= "theadtablecellTimesummary"><b >Employement Type</b></TableCell>
                                   <TableCell className= "theadtablecellTimesummary"><b >Employee Project Start Date</b></TableCell>
                                   <TableCell className= "theadtablecellTimesummary"><b >Employee Project End Date</b></TableCell>
                                   <TableCell className= "theadtablecellTimesummary"><b >Role Assignment in Project</b></TableCell>
                                   <TableCell className= "theadtablecellTimesummary"><b >Resource Allocation Percentage</b></TableCell>
                                   <TableCell className= "theadtablecellTimesummary"><b >Manager Name</b></TableCell> 
                                   <TableCell className= "theadtablecellTimesummary"><b >Manager Email</b></TableCell>
                                    <TableCell className= "theadtablecellTimesummary"><b >Task Id</b></TableCell>
                                    <TableCell className= "theadtablecellTimesummary"><b >Task Name</b></TableCell>
                                    <TableCell className= "theadtablecellTimesummary"><b >Project Owner</b></TableCell>
                                    <TableCell className= "theadtablecellTimesummary"><b >Shift</b></TableCell>
                                    <TableCell className= "theadtablecellTimesummary"><b >Cost Center</b></TableCell>
                                    <TableCell className= "theadtablecellTimesummary"><b >Task Status</b></TableCell>
                                    <TableCell className= "theadtablecellTimesummary"><b >Task/Leave Type</b></TableCell>
                                    <TableCell className= "theadtablecellTimesummary"><b >Time Entry Date</b></TableCell>
                                    <TableCell className= "theadtablecellTimesummary"><b >Hours Logged</b></TableCell>
                                    <TableCell className= "theadtablecellTimesummary"><b >Task Description</b></TableCell>
                                    <TableCell className= "theadtablecellTimesummary"><b >Timesheet Approval Status</b></TableCell>
                                    <TableCell className= "theadtablecellTimesummary"><b >Approval/Reject Date Time</b></TableCell>
                                    <TableCell className= "theadtablecellTimesummary"><b >Rejected Reason</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            <Loader loading={loading} />
                                {projectSummaryReportList.map((report, index) => (
                                    <TableRow key={index}>
                                        <TableCell   className="TableCellReportView">{(offset - 1) * itemsPerPage + index + 1}</TableCell>
                                        <TableCell  className="TableCellReportView">{report.project_name}</TableCell>
                                      <TableCell   className="TableCellReportView">{report.account_name}</TableCell>
                                        <TableCell   className="TableCellReportView">{report.project_type_name}</TableCell>
                                        <TableCell  className="TableCellReportView">{report.first_name}</TableCell>
                                        <TableCell   className="TableCellReportView">{report.business_email}</TableCell>
                                        <TableCell   className="TableCellReportView">{report.employee_code}</TableCell>
                                        <TableCell  className="TableCellReportView">{formatDate(report.date_of_joining)}</TableCell>
                                        <TableCell   className="TableCellReportView">{report.work_location_name}</TableCell>
                                        <TableCell   className="TableCellReportView">{report.employment_type_name}</TableCell>
                                        <TableCell  className="TableCellReportView">{formatDate(report.start_date)}</TableCell>
                                        <TableCell   className="TableCellReportView">{formatDate(report.end_date)}</TableCell>
                                        <TableCell   className="TableCellReportView">{report.project_role_name}</TableCell>
                                        <TableCell  className="TableCellReportView">{report.percent_allocation}</TableCell>
                                        <TableCell   className="TableCellReportView">{report.manager_first_name}</TableCell>
                                        <TableCell  className="TableCellReportView">{report.manager_business_email}</TableCell>
                                        <TableCell   className="TableCellReportView">{report.task_code}</TableCell>
                                        <TableCell   className="TableCellReportView">{report.task_name}</TableCell>
                                        <TableCell  className="TableCellReportView">{report.owner_name}</TableCell>
                                        <TableCell   className="TableCellReportView">{report.shift_name}</TableCell>
                                        <TableCell  className="TableCellReportView">{report.cost_center}</TableCell>
                                        <TableCell   className="TableCellReportView">{report.task_status}</TableCell>
                                        <TableCell   className="TableCellReportView">{report.hours_type}</TableCell>
                                        <TableCell  className="TableCellReportView">{formatDate(report.date)}</TableCell>
                                        <TableCell   className="TableCellReportView">{report.hours_logged}</TableCell>
                                        <TableCell  className="TableCellReportView">{renderDescription(report.description, index)}</TableCell>
                                        <TableCell   className="TableCellReportView">{report.approval_status}</TableCell>
                                        <TableCell   className="TableCellReportView">{formatDate(report.approval_date)}</TableCell>
                                        <TableCell  className="TableCellReportView">{report.approval_reason}</TableCell>
                                       
{/*                                       
                                    <TableCell  className="TableCell">
                                        {
                                            (titleList.isActive) ?
                                                <span style={{ color: "green" }}> Active </span>
                                                : <span style={{ color: "red" }}> InActive </span>
                                        }
                                    </TableCell> */}
                                        
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
      </TableContainer>
      </Grid>
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination
          count={Math.ceil(count / itemsPerPage)}
          page={offset}
          
          siblingCount={1}
          onChange={(event, value) => handlePagination(value)}
          color="primary"
        />
      </Box>
    </div>
  );
};

export default TimesheetSummaryView;

