import { toast } from "react-toastify";
import * as moment from "moment";

// custom pages
import config from "config/config";
import HandleApiResponse from "utils/handleApiResponse";

const reportsAPI = () => {
  const { handleResponse, handleError } = HandleApiResponse();

  async function getAllManagerProjects() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/reports/timesheet-summary/projects`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllManagerEmployees() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/reports/timesheet-summary/employees`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getTimesheetSummaryReportAPI(body) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };
      let res = await fetch(
        `${config.host}/panel/reports/timesheet-summary`,
        obj
      );
      if (res.ok) {
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `TimesheetSummary ${moment().format("YYYY-MM-DD h_mm_ss A")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        toast.success("File Downloaded Successfully", {
          toastId: "file-success",
        });
        return;
      }
      toast.error("File Not Found", { toastId: "file-error" });
      return;
    } catch (err) {
      handleError(err);
    }
  }

  async function getTimesheetSummaryReportViewAPI(body) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };
      let res = await fetch(
        `${config.host}/panel/reports/timesheet-summary-view`,
        obj
      );
     
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


  async function getAllProjectNames() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/reports/weekly-report-projects`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllProjectTasks(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(
        `${config.host}/panel/reports/weekly-report-tasks?query=${query}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getWeeklyReportAPI(body) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };
      let res = await fetch(`${config.host}/panel/reports/weekly-report`, obj);

      if (res.ok) {
        const contentType = res.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
          const json = await res.json();
          if (json.message === "No data found.") {
            toast.error("No data found", { toastId: "no-data-error" });
            return;
          }
        } else {
          const blob = await res.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `WeeklyReport ${moment().format("YYYY-MM-DD h_mm_ss A")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
          toast.success("File Downloaded Successfully", {
            toastId: "file-success",
          });
          return;
        }
      } else {
        toast.error("File Not Found", { toastId: "file-error" });
        return;
      }
    } catch (err) {
      handleError(err);
    }
  }

  async function getWeeklyReportView(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(
        `${config.host}/panel/reports/weekly-report-view?query=${query}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


 


  /////////////////////// Non Reported hours summary report ////////////////

  async function getAllDepartmentsAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/reports/all-departments`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getNonReportedHoursSummaryReportAPI(body) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };
      let res = await fetch(
        `${config.host}/panel/reports/nonReported-hours-summary`,
        obj
      );
      if (res.ok) {
        const contentType = res.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
          const json = await res.json();
          if (json && json?.message) {
            toast.error(json.message, { toastId: "no-data-error" });
            return;
          }
        } else {
          const blob = await res.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Non-Complaince Report  ${moment().format("YYYY-MM-DD h_mm_ss A")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
          toast.success("File Downloaded Successfully", {
            toastId: "file-success",
          });
          return;
        }
      } else {
        toast.error("File Not Found", { toastId: "file-error" });
        return;
      }
    } catch (err) {
      handleError(err);
    }
  }


  return {
    getAllManagerProjects,
    getAllManagerEmployees,
    getTimesheetSummaryReportAPI,
    getTimesheetSummaryReportViewAPI,
    getAllProjectNames,
    getAllProjectTasks,
    getWeeklyReportAPI,
    getWeeklyReportView,
    

    getAllDepartmentsAPI,
    getNonReportedHoursSummaryReportAPI
  };
};

export default reportsAPI;
