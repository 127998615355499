import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import "./Training.css"
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { toast } from "react-toastify";
import Loader from "components/Loader";
import projectAPI from 'apis/panel/project/project';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";

const EditTrainings = () => {
  const navigate = useNavigate();
  const [loader2, setLoader2] = useState(false);
  const { getprojectfindone, getAllProjectstypeAPI, getprojectupadte } = projectAPI();

  const projectId = useParams().id; // Also fixed this line
  const [isEditMode, setIsEditMode] = useState(false);
  const [projectcode, setprojectcode] = useState('');
  const [projectname, setprojectename] = useState('');
  const [accountname, setaccountname] = useState('');
  const [projecttypeid, setprojecttypeid] = useState('');
  const [projecttypename, setprojecttypename] = useState('');
  const [startdate, setstartdate] = useState(null);
  const [enddate, setendend] = useState(null);
  const [status, setstatus] = useState('');
  const [description, setdescription] = useState('');
  const [projecttypeoption, setprojecttypeoption] = useState([]);
  
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: "15px", // Adjust the padding as needed
  }));


  const StyledTableCellBody = styled(TableCell)(({ theme }) => ({
    padding: "10px", // Set a fixed height for the cell
    paddingLeft: "15px",
  }));


//   const handlestartAccept = (date) => {
//     setstartdate(moment(date).format("MM/DD/YYYY"));
//   };

//   const handleendAccept = (date) => {
//     if (date === null) {
//       setendend(null);
//     } else {
//     //   setendend(moment(date).format("MM/DD/YYYY"));
//     }
//   };

  const handleprojectnameChange = (e) => {
    setprojectename(e.target.value);
  };

  const handleaccountChange = (e) => {
    setaccountname(e.target.value);
  };

  const handlestatusChange = (e) => {
    setstatus(e.target.value);
  };

  const handledescriptionChange = (e) => {
    setdescription(e.target.value);
  };

  const handleprojectcodeChange = (e) => {
    setprojectcode(e.target.value);
  };

  const handleNavigate = (e) => {
    navigate("/panel/project");
  };

  useEffect(() => {
    handlefindonee(projectId);
  }, [projectId]);

  const handlefindonee = async () => {
    setLoader2(true);
    const data = await getprojectfindone(projectId);
    setLoader2(false);
    setprojectcode(data?.data?.projectCode);
    setprojectename(data?.data?.projectName);
    setaccountname(data?.data?.accountName);
    setstartdate(data?.data?.startDate);
    setendend(data?.data?.endDate);
    setstatus(data?.data?.status);
    setprojecttypeid(data?.data?.projectTypes?.projectTypeId);
    setprojecttypename(data?.data?.projectTypes?.projectTypeName);
    setdescription(data?.data?.projectDescription);
  };

  const [errorFields, setErrorFields] = useState({
    projectid: false,
    projectname: false,
    accountname: false,
    projecttype: false,
    startdate: false,
    status: false
  });

  const handleGetAllprojectype = async () => {
    try {
      let res = await getAllProjectstypeAPI();
      setprojecttypeoption(res?.data || []);
    } catch (err) { }
  };

  useEffect(() => {
    handleGetAllprojectype();
  }, []);

  const handleprojecttype = (e) => {
    const selectedprojecttype = projecttypeoption.find(option => option.projectTypeId === e.target.value);
    setprojecttypename(selectedprojecttype.projectTypeName);
    setprojecttypeid(selectedprojecttype.projectTypeId);
  };

  const handleprojectupdate = async (e) => {
    setLoader2(true);
    e.preventDefault();
    const errors = {};
    if (!projectcode) errors.projectid = true;
    if (!projectname) errors.projectname = true;
    if (!accountname) errors.accountname = true;
    if (!projecttypeid) errors.projecttype = true;
    if (!startdate) errors.startdate = true;
    if (!status) errors.status = true;
    setErrorFields(errors);
    if (Object.keys(errors).length === 0) {
      const values = {
        projectId: projectId,
        projectTypeId: projecttypeid,
        projectName: projectname,
        projectCode: projectcode,
        accountName: accountname,
        projectDescription: description,
        startDate: startdate,
        endDate: enddate,
        status: status
      };
      const data = await getprojectupadte(values);
      if (data.status === 'Ok') {
        navigate("/panel/project");
        setLoader2(false);
        return toast.success("Record updated successfully.", { toast: "Record updated successfully." });
      } else {
        setLoader2(false);
        return toast.error("Error updating Data", { toast: "Error updating Data" });
      }
    } else {
      setLoader2(false);
      return toast.error("Error saving Data", { toast: "Error saving Data" });
    }
  };

  return (
    <div className='ProjectCont'>
      <Loader loading={loader2} />
      <Grid container className="editsetupmaincont" sx={{ pl: 3, pr: 3, mt: 2, mb: 1 }}>
        <Box className="addnewprojecthead">Trainee Details</Box>
        <Box>
          {!isEditMode &&
            <Button className="projsetupedit" onClick={toggleEditMode}>
              Edit
            </Button>
          }
        </Box>
      </Grid>
      <Grid container spacing={2} sx={{ pl: 3, mt: 2 }} >
        {isEditMode ? (
          <Grid item xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Box className="label">Employee Id <span style={{ color: "red" }}>*</span></Box>
            <TextField
              id="projectCode"
              placeholder="Enter the Project Id"
              variant="outlined"
              disabled={true}
              fullWidth 
              value={projectcode}
              onChange={handleprojectcodeChange}
            />
            {errorFields.projectid && <span className="error-msg">This is required Field</span>}
          </Grid>
        ) : (
          <Grid item xs={12} sm={3.5} md={3.5} lg={3.5} sx={{ mt: "20px" }}>
            <Box className="editlabel">Employee Id</Box>
            <Box className="editvalue">{projectcode}</Box>
          </Grid>
        )}

{isEditMode ? (
          <Grid item xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Box className="label">Full Name <span style={{ color: "red" }}>*</span></Box>
            <TextField
              id="projectCode"
              placeholder="Enter the Project Id"
              variant="outlined"
              disabled={true}
              fullWidth 
              value={projectcode}
              onChange={handleprojectcodeChange}
            />
            {errorFields.projectid && <span className="error-msg">This is required Field</span>}
          </Grid>
        ) : (
          <Grid item xs={12} sm={3.5} md={3.5} lg={3.5} sx={{ mt: "20px" }}>
            <Box className="editlabel">Full Name</Box>
            <Box className="editvalue">{projectcode}</Box>
          </Grid>
        )}

{isEditMode ? (
          <Grid item xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Box className="label">Email <span style={{ color: "red" }}>*</span></Box>
            <TextField
              id="projectCode"
              placeholder="Enter the Project Id"
              variant="outlined"
              disabled={true}
              fullWidth 
              value={projectcode}
              onChange={handleprojectcodeChange}
            />
            {errorFields.projectid && <span className="error-msg">This is required Field</span>}
          </Grid>
        ) : (
          <Grid item xs={12} sm={3.5} md={3.5} lg={3.5} sx={{ mt: "20px" }}>
            <Box className="editlabel">Email</Box>
            <Box className="editvalue">{projectcode}</Box>
          </Grid>
        )}

{isEditMode ? (
          <Grid item xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Box className="label">Date of Joining <span style={{ color: "red" }}>*</span></Box>
            <TextField
              id="projectCode"
              placeholder="Enter the Project Id"
              variant="outlined"
              disabled={true}
              fullWidth 
              value={projectcode}
              onChange={handleprojectcodeChange}
            />
            {errorFields.projectid && <span className="error-msg">This is required Field</span>}
          </Grid>
        ) : (
          <Grid item xs={12} sm={3.5} md={3.5} lg={3.5} sx={{ mt: "20px" }}>
            <Box className="editlabel">Date of Joining</Box>
            <Box className="editvalue">{projectcode}</Box>
          </Grid>
        )}
      </Grid>


      <div className="ProjectCont">
      <Loader loading={loader2} />
      <Grid
        container
        className="editsetupmaincont"
        sx={{ pl: 3, pr: 3, mt: 2, mb: 1 }}
      >
        <Box className="addnewprojecthead">Certification</Box>
        <Box>
          <Button
            className="TrainingEditAddNew-Button"
            // onClick={handleOpenAddNewOwnerPopup}
          >
            Add New
          </Button>
        </Box>
      </Grid>
      <Grid container spacing={2} sx={{ pt: 1, pl: 4, pr: 3, mt:1 }}>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", border: 1, borderColor: "grey.300" }}
        >
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <b className="tariningtable-header"> Training Batch</b>
                </StyledTableCell>
                <StyledTableCell>
                  <b className="tariningtable-header"> Training Start Date</b>
                </StyledTableCell>
                <StyledTableCell>
                  <b className="tariningtable-header"> Training End Date</b>
                </StyledTableCell>
                <StyledTableCell>
                  <b className="tariningtable-header">Certification Date</b>
                </StyledTableCell>
                <StyledTableCell>
                  <b className="tariningtable-header">Certification Result</b>
                </StyledTableCell>
                <StyledTableCell>
                  <b className="tariningtable-header">Batch</b>
                </StyledTableCell>
              </TableRow>
            </TableHead>

      
                <TableBody>
                  <TableRow >
                    <StyledTableCellBody>
                      <b className="">
                       ownerName
                      </b>
                    </StyledTableCellBody>
                    <StyledTableCellBody>
                      <b className="">
                        {/* {owner?.status === "Active" ? (
                          <span style={{ color: "green", fontWeight: "bold" }}>
                            {owner?.status}
                          </span>
                        ) : (
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            {owner?.status}
                          </span>
                        )} */}
                        12-08-2024
                      </b>
                    </StyledTableCellBody>
                    <StyledTableCellBody>
                  12-08-2024
                    </StyledTableCellBody>
                    <StyledTableCellBody>
                  12-08-2024
                    </StyledTableCellBody>
                    <StyledTableCellBody>
                 
                       <input></input>
                    
                    </StyledTableCellBody>
                    <StyledTableCellBody>
                      <b className="">
                       <input></input>
                      </b>
                    </StyledTableCellBody>
                  </TableRow>
                </TableBody>
           
          </Table>
        </TableContainer>
      </Grid>


        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Pagination
            // count={Math.ceil(count / 10)}
            // page={offset}
            siblingCount={1}
            // onChange={(event, value) => handlePagination(value)}
            color="primary"
          />
        </Box>

    </div>
    </div>
  );
}

export default EditTrainings;
